import { defineStore } from 'pinia'
import { useAuth } from '@/stores/auth';
import { serialize, deserialize} from '@/stores/utils'
import { components } from "@/interfaces/schema"
import { apiUrl } from '@/env'


// Import schemas from generator to define the cache
type Item = components["schemas"]["Item"]
type ItemCreate = components["schemas"]["ItemCreate"]
type ItemUpdate = components["schemas"]["ItemUpdate"]


// Define some constants
const apiBase = `${apiUrl}/api/v1/f11`
const storeName = 'f11Locations'

// Need to provide these for each type of item
// @ts-ignore
function ItemUpdateFromItem({_id, ...rest}) { return rest }
// @ts-ignore
function ItemCreateFromItem({_id, ...rest}) { return rest }
// @ts-ignore
function ItemFromItemUpdate(itemUpdate, key) { return {...itemUpdate, id: Number.parseInt(key)} }
// @ts-ignore
function ItemFromItemCreate(itemCreate, key) { return {...itemCreate, id: Number.parseInt(key)} }
// @ts-ignore
function KeyFromItem(item) { return `${item.unit_short_name}` } // TODO: Create id in F11 locations schema
// @ts-ignore
function TempKey(itemCreate, created) { return `${-(created.size + 1)}` }

export const useF11LocationsStore = defineStore(storeName, {
    state: () => {
        return {   
            cache: new Map<string, Item>(), // server ids
            created: new Map<string, Item>(), // local ids
            updated: new Map<string, Item>(), // server ids
            removed: [] as string[], // server ids
            f11selected: "003"
        }
    },
    persist: { serializer: { serialize, deserialize } },

    actions: {
        async refresh() {
            for(const [key, item] of new Map(this.updated) ) {
                // @ts-ignore
                await this.update( ItemUpdateFromItem(item), key )
            }

            for(const [key, item] of new Map(this.created) ) {
                // @ts-ignore
                await this.create( ItemCreateFromItem(item), key)
            }

            for(const key of [...this.removed]) {
                await this.remove(key)
            }
            await this.getAll()
        },
        async getAll() {
            const auth = useAuth();
            try {
                const response = await auth.get(`${apiBase}/`)
                for (const item of response.data) {               
                    this.cache.set(KeyFromItem(item), item)
                }
                console.log(`Store[${storeName}]: Got all successfully`)
            }  catch(err: any) {
                console.log(`Store[${storeName}]: Error in getAll`)
            }
            
        },
        async remove(key: string) {
            if(this.created.has(key)) {
                console.log(`Store[${storeName}]: Delete succeeded; local item`)
                this.created.delete(key)
                return true
            }

            const auth = useAuth()
            try {
                await auth.delete(`${apiBase}/${key}`)
                console.log(`Store[${storeName}]: Delete succeeded; API item`)
                this.cache.delete(key)
                if ( this.removed.includes(key) ) {
                    const index = this.removed.indexOf(key)
                    this.removed.splice(index, 1)
                }
                return true
            } catch(err: any) {
                if(err.response?.status == 404) {
                    if ( this.removed.includes(key) ) {
                        const index = this.removed.indexOf(key)
                        this.removed.splice(index, 1)
                    }
                    this.cache.delete(key)
                    return true
                }

                console.log(`Store[${storeName}]: Couldn't delete on API, using local cache`)
                if ( !this.removed.includes(key) ) {
                    this.removed.push(key)
                }
                return false
            }
        },
        async create(itemCreate: ItemCreate, tempKey = '') {
            const auth = useAuth()

            try {
                const response = await auth.post(`${apiBase}`, itemCreate)
                console.log(`Store[${storeName}]: Create succeeded, API item`)
                const item = response.data
                const key = KeyFromItem(item)
                this.cache.set(key, item)
                if(this.created.has(tempKey)) {
                    this.created.delete(tempKey)
                }
                return true
            } catch(err: any) {
                console.log(`Store[${storeName}]: Couldn't create on API, using local`)
                const key = tempKey == '' ? TempKey(itemCreate, this.created) : tempKey
                this.created.set(key, ItemFromItemCreate(itemCreate, key))
                return false
            }
        },
        async update(itemUpdate: ItemUpdate, key: string) {
            if(this.created.has(key)) {
                return this.create(itemUpdate as ItemCreate, key)
            }

            const auth = useAuth()

            try {
                const response = await auth.put(`${apiBase}/${key}`, itemUpdate)
                console.log(`Store[${storeName}]: Update succeeded, API item`)
                const item = response.data
                this.cache.set(key, item)
                if( this.updated.has(key) ) {
                    this.updated.delete(key)
                }
                return true
            } catch(err: any) {
                console.log(`Store[${storeName}]: Couldn't update on API, using local`)
                this.updated.set(key, ItemFromItemUpdate(itemUpdate, key))
                return false
            }
        },
        get(key: string) {
            return this.cache.get(key)
        },
        clearPending() {
            this.updated.clear()
            this.created.clear()
            this.removed = []
        }
    },
    getters: {
        list(state) {
            return Array.from(state.cache.values()).flatMap((item) => {
                const key = KeyFromItem(item)
                if( state.removed.includes(key) ) {
                    return []
                }
                if( state.updated.has(key) ) {
                    return [ this.updated.get(key) ]
                }
                return [item]
            }).concat(Array.from(state.created.values()))
        },
        isDirty(state) {
            return state.updated.size > 0 || state.created.size > 0 || state.removed.length > 0
        }
    }
})
    