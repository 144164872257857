import { createRouter, createWebHistory} from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { useAuth } from '@/stores/auth';
import { useMessagesStore } from '@/stores/messages';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/f11'
  },
  {
    path: '/f11',
    component: () => import ('../views/F11Page.vue'),
  },
  {
    //F11DetailPage.vue'),
    path: '/f11DetailView/:apparatusSeatId/:apparatusType',
    //path: '/f11DetailView/:apparatusSeatId([0-9]+)/:apparatusType',
    component: () => import ('../components/F11/F11DetailView.vue'),
  },
  {
    path: '/login',
    component: () => import ('../views/Login.vue'),
  },
  {
    path: '/logout',
    component: () => import ('../views/HomePage.vue'),
  },
  {
    path: '/calendar',
    component: () => import ('../views/Calendar2.vue')
  },
  {
    path: '/segment',
    component: () => import ('../views/Segment.vue')
  },
  {
    path: '/requests',
    component: () => import ('../views/Requests.vue') 
  },
  {
    path: '/hires',
    component: () => import ('../views/Hires.vue') 
  },
  {
    path: '/messages',
    component: () => import ('../views/Messages.vue') 
  },
  {
    path: '/requests',
    component: () => import ('../views/Requests.vue') 
  },
  // { ### original JD's code, probably best looking page in app so far ###
  //   path: '/reports',
  //   component: () => import ('../views/AssignHireActivationPage.vue') 
  // },
  {
    path: '/extensions',
    component: () => import ('../views/Extensions.vue') 
  },
  {
    path: '/extensions/19',
    redirect: '/calendar'
  },
  {
    path: '/extensions/27',
    redirect: '/calendar'
  },
  {
    path: '/extensions/:id',
    component: () => import('../views/ExtensionMissing.vue')
  },
  {
    path: '/helpGurus',
    component: () => import ('../views/HelpGurus.vue')
  },
  {
    path: '/preferences',
    component: () => import ('../views/UserPreferences.vue') 
  },
  {
    path: '/aboutJanus',
    component: () => import ('../views/AboutJanus.vue') 
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {

  const auth = useAuth()
  if(!auth.isLoggedIn && to.path != '/login') {
    return '/login'
  }
  if(auth.isLoggedIn && to.path == '/login') {
    return ''
  }
  
  const messagesStore = useMessagesStore()
  if((messagesStore.hasRequired && to.path != '/messages') && to.path != '/login') {
    return '/messages'
  }

})

export default router