import { defineStore } from 'pinia'
import axios from 'axios';
import qs from 'qs';
import { apiUrl } from '@/env'

export const useAuth = defineStore('auth', {
    state: () => {
        return {
            token: '',
            isLoggedIn: false,
            isOfflineMode: false,
            isExpired: false,
            isUnreachable: false
        }
    },
    persist: true,
    actions: {
        async login(username: string, password: string) {
            try {
                const response = await axios.post(`${apiUrl}/api/v1/login/access-token`, qs.stringify({
                    username: username,
                    password: password
                }))
                this.token = response.data.access_token
                this.isLoggedIn = true
            }
            catch(error: any) {
                console.log('Store[auth]: error in login')
                throw error
            }
        },
        logout() {
            this.token = ''
            this.isLoggedIn = false
        },
        async authRequest(request: any) {
            try {
                const response = await request
                this.isLoggedIn = true
                this.isExpired = false
                return response
            }
            catch(error: any) {
                if( !error.response) {
                    console.log("Store[auth]: The server was unreachable")
                    this.isUnreachable = true
                } else {
                    this.isUnreachable = false
                    if( error.response.status == 401) {
                        console.log("Store[auth]: Token was expired")
                        this.isExpired = true
                    }
                    if( error.response.status == 403) {
                        console.log("Store[auth]: Token was malformed or invalid")
                        this.token = ''
                        this.isLoggedIn = false
                        this.isExpired = false
                    }
                }
                throw error
            }
        },
        async get(url: string) {
            return await this.authRequest(axios.get(url, this.headers))
        },
        async delete(url: string) {
            return await this.authRequest(axios.delete(url, this.headers))
        },
        async post(url: string, data: any) {
            return await this.authRequest(axios.post(url, data, this.headers))
        },
        async put(url: string, data: any) {
            return await this.authRequest(axios.put(url, data, this.headers))
        }
    },
    getters: {
        headers(state) {
            return { headers: { Authorization: `Bearer ${state.token}` } }
        }
    }
})