import {defineStore} from 'pinia'

export const useMessagesStore = defineStore("messages", {
    state: () => ({
        messages: [
            {id: 1, author: "System", required: true, received: "1/12/2023", opened: "", subject: "Fitness Evaluation Reminder", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
            {id: 2, author: "System", required: true, received: "1/1/2023", opened: "", subject: "Driver's License Expiring!", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
            {id: 3, author: "System", required: false, received: "2/13/2023", opened: "", subject: "Captain's Birthday", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
            {id: 4, author: "System", required: false, received: "2/11/2023", opened: "", subject: "Charity Drive Event", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
            {id: 5, author: "Sockets", required: false, received: "2/11/2023", opened: "", subject: "Websocket Test", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
        ]
    }),

    persist: true,

    getters: {
        hasRequired(state) {
            return state.messages.filter(message => message.required && message.opened == '').length > 0
        },
        unreadCount(state) {
            return state.messages.filter(message => message.opened == '').length
        }
    },

    actions: {
        handleOpen(id) { 
            this.messages = this.messages.map((message) => message.id == id ? {...message, opened: "2/14/2023"} : message)
        }
    }
})