export const serialize = (_state: any) => {
    const replacer = (key: any, value: any) => {
        if (value instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(value.entries())
            }
        }
        else if (value instanceof Set) {
            return {
                dataType: 'Set',
                value: Array.from(value)
            }
        } else {
            return value
        }
    }

    return JSON.stringify(_state, replacer)
}

export const deserialize = (string: any) => {
    const reviver = (key: any, value: any) => {
        if(typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.value)
            }
            if (value.dataType === 'Set') {
                return new Set(value.value)
            }
        }
        return value
    }

    return JSON.parse(string, reviver)
}